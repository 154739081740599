import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CustomLoaderModule} from '@modules/custom-loader';
import {ImagePreviewModule} from '@modules/image-preview';

import {QueueFileDownloaderService} from '@directives/file-downloader/services/queue-file-downloader.service';

import {AddedFileToQueueModalModule} from './added-file-to-queue-modal/added-file-to-queue-modal.module';
import {CloudFileDownloaderDirective} from './directives/cloud-file-downloader.directive';
import {FileDownloaderDirective} from './directives/file-downloader.directive';
import {FileDownloaderService} from './services/file-downloader.service';

@NgModule({
  declarations: [FileDownloaderDirective, CloudFileDownloaderDirective],
  imports: [CommonModule, CustomLoaderModule, ImagePreviewModule, AddedFileToQueueModalModule],
  exports: [FileDownloaderDirective, CloudFileDownloaderDirective],
  providers: [FileDownloaderService, QueueFileDownloaderService],
})
export class FileDownloaderModule {
}
